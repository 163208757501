<template>
  <v-layout>
    <v-container fluid>
    <div class="d-flex justify-space-between align-center pa-4">
      <p class="display-1 text--primary">
        {{ $t("labels.label") }}
      </p>
    </div>
    <v-row class="d-flex justify-center align-start align-content-center">
      <v-col cols="7">
        <v-row >
          <v-col cols="12">
            <v-row class="ma-4">
              <v-form
                ref="form"
                v-model="valid"
                style="width: 100%; max-width: 100vw"
                class="d-flex justify-space-around align-center"
              >
                <v-col class="col-6 py-0">
                  <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    :clearable="true"
                    outlined
                    dense
                    :items="filteredLabelsEnum"
                    :rules="requireRules"
                    class="required"
                    return-object
                    item-text="text"
                    item-value="id"
                    persistent-hint
                    v-model="labelType"
                    @change="changeLabelType()"
                    @click:clear="clearLabelType()"
                  >
                    <template v-slot:label>
                      {{ $t("records.titles.labels.labelType") }}
                      <span class="red--text"><strong>*</strong></span>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-chip small>
                        {{ item.text | truncate(15) }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="col-6 py-0">
                  <v-autocomplete
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    :clearable="true"
                    outlined
                    dense
                    :items="getPrintFormatList"
                    :rules="requireRules"
                    class="required"
                    item-text="type"
                    persistent-hint
                    v-model="printType"
                    small-chips
                    @change="setLabelAndPrint()"
                    @click:clear="clearPrintType()"
                    :disabled="!labelType"
                  >
                    <template v-slot:label>
                      {{ $t("records.titles.labels.printType") }}
                      <span class="red--text"><strong>*</strong></span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-form>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-window v-model="onboarding">
              <v-overlay
                :value="loadingForm"
                absolute
                color="white"
                :opacity="50"
              >
                <v-progress-circular
                  indeterminate
                  size="64"
                  color="primary"
                />
              </v-overlay>
              <v-window-item eager>
                <v-card :elevation="0" class="pl-2">
                  <v-card-text>
                    <v-form ref="form1" v-model="valid1[1]">
                      <v-row>
                        <v-col class="col-12 py-0">
                          <v-row
                            v-if="
                              formData[0].printType !== '' &&
                              formData[0].printType
                            "
                          >
                            <v-card elevation="0" width="100%">
                              <v-card-title class="headline">{{ $t("reports.titles.qrData") }}
                              </v-card-title>
                              <v-card-text class="pt-0">
                                <v-row align="center" class="mx-0 pb-0">
                                  <div class="mt-4 pb-2 text-subtitle-1">
                                    <span class="font-weight-bold">
                                      {{ $t("reports.fields.product") }}:
                                    </span>
                                    {{ item.product }}
                                  </div>
                                </v-row>
                                <v-row align="center" class="mx-0">
                                  <div class="pt-0 pb-2 text-subtitle-1">
                                    <span class="font-weight-bold">
                                      {{ $t("reports.fields.lot") }}:
                                    </span>
                                    {{ item.lot }}
                                  </div>
                                </v-row>
                              </v-card-text>

                              <v-divider class="mx-4"></v-divider>

                              <v-card-title class="headline">
                                {{ $t("reports.titles.impressions") }}
                              </v-card-title>

                              <v-card-text>
                                <v-row align="center" class="mx-0 pb-0">
                                  <v-col cols="5" class="pl-0">
                                    <v-text-field
                                      v-model="
                                        formData[0].productsData[0].print
                                      "
                                      class="required"
                                      color="primary"
                                      dense
                                      outlined
                                      v-on:keyup.86="replace"
                                      v-on:keyup.ctrl.86="replace"
                                      :rules="item.availablePrint !== -1 ? requireAndFlowRules(isValid) : []"
                                    >
                                      <template v-slot:label>
                                        {{ $t("reports.fields.printCount") }}
                                        <span v-if="item.availablePrint !== -1" class="red--text"><strong>*</strong></span>
                                      </template>
                                    </v-text-field>

                                  </v-col>
                                  <v-col cols="7">
                                    <div class=" pb-5 text-subtitle-1" v-if="item.availablePrint !== -1">
                                      <span class="font-weight-bold">
                                        {{ $t("reports.fields.printLimit") }}:
                                      </span>
                                      {{ item.availablePrint }}
                                    </div>
                                    <div class="mt-1 pb-2 text-subtitle-1 d-flex" v-else>
                                      <span class="font-weight-bold">
                                        {{ $t("reports.fields.printLimit") }}:
                                      </span>
                                      <p class="ma-0 pa-0 font-weight-bold" style="font-size: 20px">&nbsp; &infin;</p>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5">
        <v-card elevation="0" width="100%">
          <v-card-title class="neuter--text pb-1 pt-3 pl-1">
            {{$t("records.titles.labels.exemplo") }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
            :class="
              labelType
                ? 'd-flex flex-wrap justify-left  pb-0 '
                : 'd-flex flex-wrap justify-center pb-0 horizontalImage'
            "
          >
            <v-img :src="getLabelTemplate" contain></v-img>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
      class="ml-3 mt-1"
      :value="!ready"
      absolute
      color="white"
      :opacity="50"
    >
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>

  </v-container>
    <v-footer absolute class="pa-6">
      <v-spacer></v-spacer>
      <v-btn
        small
        text
        color="neuter"
        @click="printCancel()"
      >
        {{ $t("common.buttons.cancel") }}
      </v-btn>
      <v-btn
        small elevation="0" color="primary" class="t-bw-primary--text ml-3 mr-4"
        :disabled="!(valid1[1] && isValid)"
        :loading="loadingForm"
        @click="generate()"

      >
        {{ $t("common.buttons.generate") }}
      </v-btn>
    </v-footer>
  </v-layout>
</template>

<script>
  import { mapActions, mapGetters } from "vuex"

  import _ from "lodash"
  import moment from "moment"
  import LanguageService from "@/services/LanguajeService"

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    props: {
      item: {
        type: Object,
      },
    },
    data: () => ({
      e1: 0,
      ready: false,
      profile: null,
      valid: false,
      valid1: {},
      labelTemplate: "",
      onboarding: 0,
      dialog: false,
      editedIndex: -1,
      refers: [],
      modelRefers: {
        packingDate: "",
        expirationDate: "",
      },
      date: "",
      formData: [],
      formDataCopy: [],
      modelProduct: [],
      labelType: null,
      printType: null,
      defaultItem: {},
      showDialog: false,
      title: "Información",
      dialogText: "Añada un texto a este dialogo",
      max: 40,
      loadingForm: false,
      defaultFilters: {
        companyId: PROFILE.company_id,
        search: "",
        pageableDTO: {
          page: 0,
          size: 15,
          sortBy: "id",
          direction: "ASC",
        },
      },
    }),

    computed: {
      filteredLabelsEnum() {
        return this.labelsEnum.filter(label =>
          label.company.includes(0) || label.company.includes(PROFILE.company_id,)
        );
      },
      recordSelected: {
        get() {
          return this.$store.getters["records/record"]
        },
        set(val) {
          this.$store.commit("records/SET_RECORD", val)
        },
      },
      ...mapGetters({
        labelsEnum: "labels/labelsReportEnum",
        requireAndFlowRules: "general/requireAndFlowRules",
        requireRules: "general/requireRules",
      }),
      config: {
        get() {
          return this.$store.getters["general/config"]
        },
        set(val) {
          this.$store.commit("general/SET_CONFIG", val)
        },
      },
      isValid() {
        return this.item.printLimit !== -1 ? this.formData[0].productsData[0].print <= this.item.availablePrint : this.formData[0].productsData[0].print > 0
      },
      decimalFormat() {
        return this.config.company.traceability.structure.number.afterPoint !==
          null
          ? this.config.company.traceability.structure.number.afterPoint
          : 3
      },
      getPrintFormatList() {
        return this.labelType
          ? _.find(this.labelsEnum, label =>
          label.id === this.labelType.id &&
          (label.company.includes(0) || label.company.includes(PROFILE.company_id))
        )?.types || []
          : [];
      },
      getLabelTemplate() {
        return this.printType
          ? require("@/assets/img/LabelsTemplate/label-" +
              this.labelType.id +
              this.printType +
              ".jpeg")
          : require("@/assets/img/LabelsTemplate/defaultLabelTemplate.png")
      },
    },

    async created() {
      this.config = JSON.parse(localStorage.getItem("config"))
      this.restoreModels()
      this.generateModels()
      await this.initialize()
    },

    methods: {
      generateModels() {
        this.formData.push({
          labelType: "",
          printType: "",
          productsData: [
            {
              product: null,
              lot: null,
              printLimit: null,
              print: null,
              availablePrint: null,
              printed: null,
              url: null,
              id: null
            },
          ],
        })

        this.modelProduct.push({
          product: null,
          lot: null,
          printLimit: null,
          print: null,
          availablePrint: null,
          printed: null,
          url: null,
          id: null

        })
      },
      restoreModels() {
        this.formData = []
        this.modelProduct = []
      },
      async initialize() {
        this.ready = false
        this.profile = JSON.parse(localStorage.getItem("profile"))
        this.formData[0].productsData[0].product = this.item.product
        this.formData[0].productsData[0].lot = this.item.lot
        this.formData[0].productsData[0].url = this.item.url
        this.formData[0].productsData[0].printLimit = this.item.printLimit
        this.formData[0].productsData[0].printed = this.item.printed
        this.formData[0].productsData[0].availablePrint = this.item.availablePrint
        this.formData[0].productsData[0].id = this.item.id
        this.ready = true
      },
      generate() {
        console.log(this.formData)

        this.$store.commit(
          "general/LABEL_DATA",
          _.cloneDeep({
            labelType: this.formData[0].labelType,
            printType: this.formData[0].printType,
            productsData: this.formData[0].productsData,
          })
        )
        this.$router.push({ path: "/printReportLabel" })
      },
      async setLabelAndPrint() {
        this.loadingForm = true
        _.forEach(this.formData, form => {
          form.labelType = this.labelType
          form.printType = this.printType
        })
        this.loadingForm = false
      },
      changeLabelType() {
        this.printType = ""
        _.forEach(this.formData, form => {
          form.printType = ""
        })
        this.$refs.form.resetValidation()
      },
      clearPrintType() {
        _.forEach(this.formData, form => {
          form.printType = ""
        })
      },
      clearLabelType() {
        this.labelType = ""
        this.printType = ""
        this.$refs.form.resetValidation()
      },
      printCancel() {
        this.$router.push({ name: "traceability-view" })
        this.restoreModels()
      },
      translate(key) {
        return LanguageService.getKey3(key)
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceProducedBy(event, index) {
        this.formData[index].producedBy = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceDocumentValueProduced(event, index) {
        this.formData[index].documentValueProduced = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceAllAddressProduced(event, index) {
        this.formData[index].allAddressProduced = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceDistributedBy(event, index) {
        this.formData[index].distributedBy = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
      replaceDocumentValueDistributed(event, index) {
        this.formData[index].documentValueDistributed =
          event.currentTarget.value.toString().replaceAll('"', "")
      },
      replaceAllAddressDistributed(event, index) {
        this.formData[index].allAddressDistributed = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
    },
  }
</script>

<style scoped>
  .v-stepper__step {
    padding: 10px !important;
  }

  .horizontalImage {
    width: 50%;
  }
</style>